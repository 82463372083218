<template>
  <el-dialog
    custom-class="company-info-dialog"
    :visible.sync="visible"
    top="5vh"
    width="80%"
    @close="onCloseDialog"
    v-on="$listeners">
    <div class="module-content-top-bar" v-if="showTitle">
      <h1  class="top-bar-title">{{title&&title}}</h1>
    </div>
    <div>
      <el-form
        v-if="infoType =='basic'"
        class="company-info-container el-form-item-left-lable"
        ref="companyInfoForm"
        :rules="companyInfoRules"
        :model="companyInfoFormData"
        :label-width="$i18n.locale==='cn'?'160px':'280px'">
        <div class="form-block">
          <div class="title-bar">{{ $t('companyBaseInfo') }}</div>
          <el-form-item
            :label="$t('companyName')"
            prop="companyName">
            <el-input v-model="companyInfoFormData.companyName"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('businessIncorporationNumber')"
            prop="businessIncorporationNumber">
            <el-input v-model="companyInfoFormData.businessIncorporationNumber"></el-input>
          </el-form-item>
          <el-form-item
            label="Company Abbreviation"
            prop="abbreviation">
            <el-input
              disabled
              v-model="companyInfoFormData.abbreviation">
            </el-input>
          </el-form-item>
          <el-form-item
            :label="$t('incorporationDate')"
            prop="incorporationDate">
            <el-date-picker
              style="width: 100%;"
              v-model="companyInfoFormData.incorporationDate"
              type="date"
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd 00:00:01"
              :placeholder="$t('selectDate')">
            </el-date-picker>
          </el-form-item>
          <el-form-item
            :label="$t('incorporationCountry')"
            prop="incorporationCountry">
            <country-selector
              style="width: 100%"
              v-model="companyInfoFormData.incorporationCountry">
            </country-selector>
          </el-form-item>
          <el-form-item
            label="GST Registration No."
            prop="gstRegistrationNo">
            <el-input v-model="companyInfoFormData.gstRegistrationNo"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('streetName')"
            prop="streetName">
            <el-input v-model="companyInfoFormData.streetName"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('buildingName')"
            prop="buildingName">
            <el-input v-model="companyInfoFormData.buildingName"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('unitNumber')"
            prop="unitNumber">
            <el-input v-model="companyInfoFormData.unitNumber"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('postal')"
            prop="postal">
            <el-input v-model="companyInfoFormData.postal"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('phone')"
            prop="phone">
            <el-input v-model="companyInfoFormData.phone"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('fax')"
            prop="fax">
            <el-input v-model="companyInfoFormData.fax"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('companyType')"
            prop="companyType">
            <el-select
              style="width: 100%"
              v-model="companyInfoFormData.companyType">
              <el-option
                v-for="item in companyOptions"
                :key="item.key"
                :label="item.value"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Physical Supplier"
            prop="licenseType">
            <div style="padding:0 12px;">
              <el-switch
                v-model="companyInfoFormData.licenseType"
                active-color="#8182ff"
                active-text="Yes"
                inactive-text="No"
                @change="licenseTypeChangeHandler">
              </el-switch>
            </div>
          </el-form-item>
          <el-form-item
            v-if="companyInfoFormData.licenseType"
            label="License No."
            prop="licenseNumber">
            <el-input
              v-model="companyInfoFormData.licenseNumber">
            </el-input>
          </el-form-item>
          <el-form-item
            :label="$t('email')"
            prop="email">
            <el-input v-model="companyInfoFormData.email"></el-input>
          </el-form-item>
        </div>
        <div class="form-block">
          <div class="title-bar">{{ $t('companyDocument') }}</div>
          <el-form-item
            v-for="item in fileFields.filter(item=>item!=='otherDocument')"
            :key="item"
            :label="$t(item)"
            :prop="item">
            <el-upload
              :action="fileUploadUrl"
              :accept="item==='bdnLogo'?'.jpg,.png':'.pdf'"
              :limit="1"
              :file-list="companyInfoFormData[item]"
              :on-remove="handleFileRemove(item,'single')"
              :on-success="handleFileUploadSuccess(item,'single')"
              :before-remove="(file, fileList) => handleBeforeRemove(file, fileList, item)">
              <el-button
                :type="getUploadStatus(item)?'danger':''"
                :disabled="getUploadStatus(item)"
                size="mini">
                {{ getUploadStatus(item) ? $t('uploaded') : $t('uploadFile') }}
              </el-button>
              <div
                slot="tip"
                class="el-upload__tip">
                {{ getUploadStatus(item) ? $t('uploadedAndCanDelete') : $t('uploadAttachmentPDF') }}
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item
            :label="$t('otherDocument')"
            prop="otherDocument">
            <el-upload
              :action="fileUploadUrl"
              accept=".pdf,.jpg,.png"
              :file-list="companyInfoFormData.otherDocument"
              :on-success="handleFileUploadSuccess('otherDocument','multiple')"
              :on-remove="handleFileRemove('otherDocument','multiple')"
              :before-remove="(file, fileList) => handleBeforeRemove(file, fileList)"
              multiple>
              <el-button size="mini">{{ $t('uploadFile') }}</el-button>
              <div slot="tip" class="el-upload__tip">{{ $t('uploadAttachmentPDF') }}</div>
            </el-upload>
          </el-form-item>
        </div>
      </el-form>
      <div v-else-if="infoType =='bank'">
        <update-bank
          v-for="(item, index) in bankAccList"
          :key="index"
          :refKey="index===0?'companyBank':'chaserBank'"
          :category="item.category"
          :sysOrganizationId="companyInfoFormData.id"
          :companyDefaultBank="companyDefaultBank"
          :chaserDefaultBank="chaserDefaultBank"
          :data="item.bankList"
          >
        </update-bank>
      </div>
      <div v-else-if="infoType == 'settings'">
        <update-settings
          :isPhysicalSupplier="isPhysicalSupplier"
          :sysOrganizationId="companyInfoFormData.id">
      </update-settings>
      </div>
    </div>
    <div class="flex-end" style="margin-top:10px"  v-if="infoType =='basic'" >
      <el-button
        class="second-button"
        @click="onCloseDialog">
        Close
      </el-button>
      <el-button
        class="primary-button"
        :loading="submitLoading"
        @click="submit">
        {{ $t('submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import CountrySelector from '@/components/CountrySelector'
import { mapState } from 'vuex'
import UpdateBank from './UpdateBank.vue'
import UpdateSettings from './UpdateSettings.vue'
import baseTableMixin from '@/mixins/base-table-mixin'
import { COMPANY_TYPE } from '@/constants.js'

export default {
  name: 'UpdateCompany',
  inject: ['reload'],
  mixins: [baseTableMixin],
  components: { CountrySelector, UpdateBank, UpdateSettings },
  props: {
    originalValue: {
      type: Object,
      required: true
    },

    title: String,
    infoType: String
  },
  computed: {
    ...mapState(['bankList', 'companyDefaultBank', 'chaserDefaultBank']),
    companyOptions () {
      return Object.entries(this.COMPANY_TYPE).map(([key, value]) => ({key,value}))
    },
    showTitle () {
      return this.infoType !== 'bank'
    },
    isPhysicalSupplier(){
      return   !!this.$props.originalValue?.licenseType
    }
  },
  data () {
    // 企业信息必需字段
    const companyInfoRequiredFields = [
      'id',
      'businessIncorporationNumber',
      'companyName',
      'abbreviation',
      'incorporationDate',
      'incorporationCountry',
      'streetName',
      'postal',
      'companyType',
      'licenseNumber',
      'businessIncorporationDocument',
      'bdnLogo',
      'auditStatus',
      'licenseType',
      'email',

    ]
    // 企业信息非必需字段
    const companyInfoNotRequiredFields = [
      'fax',
      'authorizationLetter',
      'gstRegistrationNo',
      'operatorLicences',
      'craftOperatorLicences',
      'otherDocument',
      'buildingName',
      'unitNumber',
      'phone',
      'cityOrState',
    ]
    const companyInfoFormData = {}
    const companyFields = [...companyInfoRequiredFields, ...companyInfoNotRequiredFields]
    companyFields.forEach(field => {
      companyInfoFormData[field] = null
    })
    // 企业信息校验规则
    const companyInfoRules = {}
    companyInfoRequiredFields.forEach(field => {
      companyInfoRules[field] = [
        { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }
      ]
      if (field === 'email') {
        companyInfoRules[field]=[{
          type: 'email',
          required: false,
          message: this.$t('emailFormatError'),
          trigger: 'blur'
        }]
      }
    })
    return {
      companyInfoNotRequiredFields,
      companyInfoRequiredFields,
      visible: false,
      companyFields,
      companyInfoRules,
      companyInfoFormData,
      fileFields: [
        'businessIncorporationDocument',
        'authorizationLetter',
        'operatorLicences',
        'craftOperatorLicences',
        'bdnLogo',
        'otherDocument'
      ],
      fileUploadUrl: '',
      submitLoading: false,
      bankAccList: [],
      COMPANY_TYPE,
      newFiles: [],
      isSummitted: false
    }
  },
  mounted () {
    this.visible = true
    this.fileUploadUrl = this.$apis.baseUrl + this.$apis.uploadFile
    Object.keys(this.companyInfoFormData).forEach(key => {
      // 文件类型字段，修改修改path为url再赋值
      if (this.fileFields.includes(key)) {
        const value = this.$_.cloneDeep(this.originalValue[key]) || []
        value.forEach(item => {
          item.url = item.path
          // 修改文件名称为原始文件名称，以便显示原始文件名称
          item.name = item.originalName || item.name
        })
        this.companyInfoFormData[key] = value
      } else {
        this.companyInfoFormData[key] = this.originalValue[key]
      }
    })
    // auditStatus与后端约定,0:初始状态,1:审批中,2:拒绝,3:通过，设置审核状态为0
    this.companyInfoFormData.auditStatus = 0

  },
  methods: {
    onCloseDialog () {
      this.visible = false
      this.$emit('closeDialog', this.infoType)
      if (this.isSummitted || this.newFiles.length === 0) return

      return new Promise((resolve, reject) => {
        this.$request.post({
          url: this.$apis.deleteFile,
          data: this.newFiles.map(file => file.id)
        }).then(res => {
          res?.code === 1000 ? resolve() : reject(new Error('fail'))
        }).catch(() => {
          reject(new Error('fail'))
        })
      })
    },
    getUploadStatus (field) {
      /**
       * 获取文件上传状态
       */
      const fileList = this.companyInfoFormData[field] ?? []
      return !!fileList.length
    },

    handleBeforeRemove (file, fileList, field) {
      const newFileIdx = this.newFiles.findIndex(nf => nf.id === file.id)
      if (newFileIdx === -1) return
      return new Promise((resolve, reject) => {
        this.$request.post({
          url: this.$apis.deleteFile,
          data: [this.newFiles[newFileIdx].id]
        }).then(res => {
          if (res?.code === 1000) {
            this.newFiles.splice(newFileIdx, 1)
            resolve()
          }
          else {
            reject(new Error('fail'))
          }
        }).catch(() => {
          reject(new Error('fail'))
        })
      })
    },
    handleFileRemove (field, count) {
      /**
       * 文件删除后删除字段值
       * @field {String} 字段名
       * @count {String} 文件数量
       */
      if (count === 'single') {
        return () => {
          this.companyInfoFormData[field] = []
        }
      }
      return (file, fileList) => {
        const index = fileList.findIndex(item => item === file)
        this.companyInfoFormData[field].splice(index, 1)
      }
    },

    handleFileUploadSuccess (field, count) {
      /**
       * 监听文件上传成功
       * @field {String} 字段名
       * @count {String} 文件数量
       */
      if (count === 'single') {
        return res => {
          if (res?.code === 1000) {
            res.data.url = res.data.path
            res.data.name = res.data.originalName || res.data.name
            this.companyInfoFormData[field] = [res.data]
            this.newFiles.push(res.data)
          }
        }
      }
      return res => {
        if (res?.code === 1000) {
          res.data.url = res.data.path
          res.data.name = res.data.originalName || res.data.name
          this.companyInfoFormData[field].push(res.data)
          this.newFiles.push(res.data)
        }
      }
    },

    submit () {
      /**
       * 提交修改
       */
      if (this.infoType === 'basic') {
        this.$refs.companyInfoForm.validate(valid => {
          if (!valid) return;
          // if (valid) {
            this.submitLoading = true
            if (!this.companyInfoFormData.licenseType) {
              this.companyInfoFormData.licenseNumber = ''
            }
            const data = this.$_.cloneDeep(this.companyInfoFormData)
            // 需要提交文件ID,获取文件ID数组,删除文件字段
            this.fileFields.forEach(field => {
              data[`${field}Ids`] = (data[field] || []).map(item => item.id)
            })
            const bodyData = {
              tableId: data.id,
              afterDetail: JSON.stringify(data)
            }
            this.doSubmitRequest(this.$apis.submitUpdateCompany, bodyData)
          // }
        })
      }
      if (this.infoType === 'bank') {
        this.$refs.myBankInfo.getPayloadData('COMPANY_BANK')
        this.$refs.chaserBankInfo.getPayloadData('CHASER_BANK')
      }
    },
    doSubmitRequest (url, data) {
      this.isSummitted = true
      this.$request.post({
        url,
        data
      }).then(res => {
        if (res?.code === 1000) {
          this.visible = false
          this.$emit('submit-success')
          this.$message.success('Pending approval from the system administrator.')
        }
      }).finally(() => {
        this.submitLoading = false
        this.$router.go(0)
      })
    },

    licenseTypeChangeHandler (value) {
    },
    getbankList (list) {
      const item1 = {
        category: 'COMPANY_BANK',
        bankList: list.length > 0 ? list.filter(l => l.bankType === 'COMPANY_BANK') : []
      }
      const item2 = {
        category: 'CHASER_BANK',
        bankList: list.length > 0 ? list.filter(l => l.bankType === 'CHASER_BANK') : []
      }
      this.bankAccList = [item1, item2]
    }
  },
  watch: {
    visible (newVal, oldVal) {
      if (newVal && this.infoType === 'bank') {
        this.$store.dispatch('getBankList')
      }
    },
    infoType: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal === 'bank') {
          this.getbankList(this.bankList)
        }
      }
    },
    'companyInfoFormData.licenseType': {
      handler (newValue) {
        if (newValue) {
          this.companyInfoRules.operatorLicences.push(
            { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }
          )
          this.companyInfoRules.craftOperatorLicences.push(
            { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }
          )
          this.companyInfoRules.licenseNumber.push(
            { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }
          )
        } else {
          this.$set(this.companyInfoRules, 'operatorLicences', [])
          this.$set(this.companyInfoRules, 'craftOperatorLicences', [])
          this.$set(this.companyInfoRules, 'licenseNumber', [])
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../index.scss";
.company-info-dialog {
  .module-content-top-bar {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .company-info-container {
    display: grid;
    height: calc(100vh - 172px);
    overflow-y: auto;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;

    .form-block {
      padding-left: 10px;;
      padding-right: 10px;
    }
    .title-bar {
      margin-bottom: 20px;
      padding: 10px;
      text-align: center;
      border-radius: 5px;
      background-color: #F2F6FC;
    }
  }
}
/deep/.el-dialog__body{
  padding-top: 0;
}

</style>
